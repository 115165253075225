<template>
  <div>
    <div class="all">
      <div class="box">
        <div class="imgsize">
          <img :src="nowImg" alt="" />
        </div>
        <div class="title">页面不见了，请稍后试试</div>
        <!-- <el-button class="btn" type="primary" size="large" round @click="back()"
        >返回上一页</el-button
      > -->
      </div>
    </div>
    <bottom-bar class="bottom"></bottom-bar>
  </div>
</template>

<script>
import BottomBar from "components/content/bottombar/BottomBar";
export default {
  components: {
    BottomBar,
  },
  data() {
    return {
      // nowImg: ''
    };
  },
  computed: {
    nowImg: function() {
      return require(`../../assets/error/${
        this.$route.query.type ? this.$route.query.type : "404"
      }.png`);
    },
  },
  // methods () {
  //   back: function () {
  //     this.$router.go(-2)
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.all {
  position: absolute;
  width: 100%;
  top: 40%;
  transform: translateY(-100%);
  .box {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .imgsize{
      width:338px;
      height:192px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .title {
      margin-top: 25px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #999999;
    }
    .btn {
      box-sizing: border-box;
      margin: 56px;
    }
  }
}

.top {
  position: relative;
}
.bottom {
  position: absolute;
  bottom: 0;
}
</style>
