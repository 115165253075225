<template>
  <div class="box">
    <el-row class="el-col_btm zzjz">
      <el-col :span="9" class="border_right">
        <div class="logo_size">
          <img src="~assets/logo.svg" alt="" />
        </div>
        <ul>
          <li
            class="asdasd"
            v-for="(item, index) in website"
            :key="index"
            @click="toMore(index)"
          >
            {{ item }}
          </li>
        </ul>
      </el-col>
      <el-col :span="6">
        <dl>
          <dt>瑞尔安心旗下网站</dt>
          <dd
            class="asdasd"
            v-for="(option, i) in options"
            :key="i"
            @click="topage(i)"
          >
            {{ option }}
          </dd>
        </dl>
      </el-col>
      <el-col :span="9" class="border_left">
        <div class="more" style="margin-left:51px;margin-top:10px">
          更多产品
        </div>
        <el-row type="flex" justify="center" style="margin-left: 28px">
          <!-- <el-col :span="8"></el-col> -->
          <el-col
            :span="16"
            v-for="(item, j) in erweima"
            :key="j"
            style="text-align: center; margin-top:unset"
          >
            <div class="imgsize">
              <img :src="item.image" alt="" class="imgsize" />
            </div>
            <span>{{ item.title }}</span>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="bottom_l">
      <el-col :span="24" style=" margin-top:20px; margin-bottom:16px">
        <div>Copyright©2016-2021 未经授权请勿转载</div>
        <div>
          <span class="show a" @click="tobei()">浙ICP备19052182号-1</span>
        </div>
      </el-col>
      <el-col :span="24" style="margin: 0 auto; text-align: center;">
        <div>特别声明：本站内容仅供参考，不作为诊断及医疗依据</div>
        <div>
          免责声明：本站部分文字及图片来自于网络，如侵犯到您的权益，请及时通知我们进行删除处理
        </div>
      </el-col>
    </el-row>
    <!-- <img src="~assets/logo.png" alt="">
      <a class="t1">瑞尔安心—健康科普平台</a>
      <a class="t2">瑞尔安心官网</a>
      <a class="t3">瑞尔安心—在线医美平台</a>
      <div class="line1"></div>
      <a class="t4">瑞尔安心旗下网站</a>
      <a class="t5">瑞尔安心—健康科普平台</a>
      <a class="t6">瑞尔安心官网</a>
      <a class="t7">瑞尔安心—在线医美平台</a>
      <div class="line2"></div>
      <div class="morepro">
        <span class="more">更多产品</span>
        <t-code :t-name="'用户端App'" class="peopleapp"></t-code>
        <t-code :t-name="'医生端App'" class="docapp"></t-code>
        <t-code :t-name="'公众号'" class="gz"></t-code>
        <t-code :t-name="'瑞尔安心官方微信'" class="wx"></t-code>
        <t-code :t-name="'瑞尔安心抖音'" class="dy"></t-code>
      </div>
      <div class="line3"></div>
      <div class="bottom">
          <span class="m1">Copyright©2016-2021 未经授权请勿转载</span>
          <span class="m2">浙ICP备11234882号-2</span>
          <span class="m3">浙公网安备5001900251234号</span>
          <span class="m4">特别声明：本站内容仅供参考，不作为诊断及医疗依据</span>
          <span class="m5">免责声明：本站部分文字及图片均来自于网络，如侵犯到您的权益，请及时通知我们进行删除处理</span>
          <span class="m6">互联网药品信息服务资格证书(浙)-经营性-2021-0145</span>
          <span class="m7">广播电视节目制作经营许可证(浙) 字第 00428号</span>
      </div> -->
  </div>
</template>

<script>
// import TCode from 'components/common/tcode/TCode'
export default {
  name: "BottomBar",
  components: {
    // TCode
  },
  data() {
    return {
      website: ["了解瑞尔安心", "联系我们","服务条款","免责声明"],
      options: [
        "瑞尔安心-健康科普平台",
        "瑞尔安心医疗设备官网",
        "宠物医生-专业宠物医疗平台",
        "心理咨询",
      ],
      erweima: [
        {
          image: require("../../../assets/kh.png"),
          title: "用户端App",
        },
        {
          image: require("../../../assets/ys.png"),
          title: "医生端App",
        },
        {
          image: require("../../../assets/gz.svg"),
          title: "瑞尔安心官方微信",
        },
      ],
    };
  },
  methods: {
    tobei() {
      window.open(
        "https://beian.miit.gov.cn/?spm=5176.17724490.J_9220772140.115.10545c73DA0EP0#/Integrated/index"
      );
    },
    topage(e) {
      if (e === 0) {
        window.open("http://www.irealcare.com/");
      }
      if (e === 1) {
        window.open("http://ht.irealcare.com/device/");
      }
      if (e === 2) {
        window.open("http://petlove.irealcare.com/");
      }
      if (e === 3) {
        window.open("http://psycho.irealcare.com/");
      }
    },
    toMore(e) {
      this.$router.push({ path: '/moreservice', query: { current: e } })
    }
  },
};
</script>

<style scoped>
.box {
  /* position: relative; */
  /* width: 1440px; */
  /* height: 594px; */
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #595959;
  width: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.logo_size {
  width: 170px;
  margin-left: 184px;
  /* margin-top: 60px; */
}
.el-col_btm {
  width: 1440px;
}
.el-col_btm .el-col {
  margin-top: 60px;
  color: #595959;
  height: 234px;
}
ul {
  margin-left: 150px;
  margin-top: 36px;
}
ul li {
  list-style: none;
  margin-bottom: 18px;
  font-size: 12px;
}
dl {
  margin-left: 93px;
}
dl dt,
.more {
  font-size: 16px;
  /* font-family: 'OPPOSans B'; */
  font-weight: bold;
  margin-bottom: 40px;
}
dl dd {
  margin-bottom: 18px;
  font-size: 12px;
  margin-inline-start: unset;
}
.imgsize {
  /* width: 100px; */
  height: 100px;
  object-fit: contain;
}
.bottom_l {
  background-color: #262626;
  height: 90px;
  margin-top: 40px;
}
.bottom_l .el-col div {
  margin-left: 16px;
  color: #8c8c8c;
  font-size: 11px;
  display: inline-block;
}
.bottom_l .el-col {
  margin: 0 auto;
  text-align: center;
}
.border_right {
  border-right: 1px solid #bfbfbf;
}
.border_left {
  border-left: 1px solid #bfbfbf;
}
.a {
  cursor: pointer;
}
.show:hover {
  color: #252222;
}
.asdasd {
  cursor: pointer;
}
</style>
